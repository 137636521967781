// Navbar dropped in from FullStackRocket... and updated jss-to-styled. muiV5

import React from 'react';
import { Link } from 'gatsby';
import { styled } from '@mui/system';
import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';

import MUILink from '@mui/material/Link';
import ContactButton from './ContactButton';

import logo from '../img/RepairMyGlasses-logo.svg';

const drawerWidth = 240;

// const useStyles = makeStyles((theme) => ({
// root: {
// 	display: 'flex',
// },
const DrawerNav = styled('nav')(({ theme }) => ({
	[theme.breakpoints.up('sm')]: {
		width: drawerWidth,
		flexShrink: 0,
	},
}));
const AppBarStyled = styled(AppBar)(({ theme }) => ({
	// color: theme.palette.primary.dark,
	// backgroundColor: 'white',
	[theme.breakpoints.up('sm')]: {
		width: `100%`,
	},
	alignItems: 'center',
}));
const MainToolbar = styled(Toolbar)(({ theme }) => ({
	maxWidth: theme.breakpoints.values.lg,
	'& a': {
		color: theme.palette.primary.dark,
		'&:hover': {
			textDecoration: 'none',
		},
	},
	[theme.breakpoints.down('sm')]: {
		justifyContent: 'space-between',
	},
	justifyContent: 'space-between',
	width: '100%',
	padding: '0 30px',
}));
// title: {
// 	flexGrow: 1,
// },
// menuButton: {
// 	marginRight: theme.spacing(2),
// 	[theme.breakpoints.up('sm')]: {
// 		display: 'none',
// 	},
// },
// toolbar: theme.mixins.toolbar,
// drawerPaper: {
// 	width: drawerWidth, ???TS
// },
// content: {
// 	flexGrow: 1,
// 	padding: theme.spacing(3),
// },
// }));

const items = [
	// { path: '/about', label: 'About' },
	// { path: '/products', label: 'Products' },
	// { path: '/blog', label: 'Blog' },
	// { path: '/contact', label: 'Contact' },
	// { path: '/contact/examples', label: 'Form Examples' },
];

export default function NavBarComponent(props) {
	const { container } = props;
	// const classes = useStyles();
	const [mobileOpen, setMobileOpen] = React.useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const drawer = (
		<div>
			<div sx={(theme) => theme.mixins.toolbar} />
			<List>
				{items.map((item) => (
					<ListItem button key={item.path}>
						<MUILink component={Link} to={item.path}>
							<ListItemText primary={item.label} />
						</MUILink>
					</ListItem>
				))}
			</List>
		</div>
	);

	return (
		<>
			<AppBarStyled position='fixed'>
				<MainToolbar maxWidth='lg' py={12}>
					<Link to='/RMG-landing-STATIC'>
						<img
							src={logo}
							alt='Repair My Glasses'
							style={{ width: '280px', margin: '26px 0 0px' }}
						/>
					</Link>
					<Hidden smDown implementation='css'>
						{items.map((item) => (
							<Button color='inherit' key={item.path}>
								<MUILink component={Link} to={item.path}>
									<ListItemText primary={item.label} />
								</MUILink>
							</Button>
						))}
					</Hidden>
					<Hidden mdUp implementation='css'>
						<IconButton
							color='inherit'
							aria-label='open drawer'
							edge='end'
							onClick={handleDrawerToggle}
						>
							<MenuIcon />
						</IconButton>
					</Hidden>
					<ContactButton
						variant='contained'
						color='secondary'
						size='lg'
						href='javascript:void(Tawk_API.toggle())'
						rel='noopener noreferrer'
					>
						Get in Touch
					</ContactButton>
				</MainToolbar>
			</AppBarStyled>
			<DrawerNav aria-label='mailbox folders'>
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Hidden smUp implementation='css'>
					<Drawer
						container={container}
						variant='temporary'
						anchor={'right'}
						open={mobileOpen}
						onClose={handleDrawerToggle}
						// classes={{
						// 	paper: classes.drawerPaper,
						// }}
						sx={{ '& .MuiDrawer-paper': { width: drawerWidth } }}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						{drawer}
					</Drawer>
				</Hidden>
			</DrawerNav>
		</>
	);
}
