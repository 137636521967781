import { styled } from '@mui/system';
import Button from '@mui/material/Button';

const ContactButton = styled(Button)(({ theme }) => ({
	'&.MuiButton-root': {
		color: theme.palette.common.white,
	},
}));

export default ContactButton;
