import * as React from "react";
import { Helmet } from "react-helmet";

import Container from '@mui/material/Container';

import FooterMui from '../components/Footer-mui';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
// import "./all.sass"; TS NOTE: replace sass with MUI theme
// import useSiteMetadata from "../../plugins/gatsby-plugin-top-layout/SiteMetadata"; TS NOTE: move Helmet to TopLayout to implement MUI theme
// import { withPrefix } from "gatsby";

const TemplateWrapper = ({ children }) => {
	// const { title, description } = useSiteMetadata();
	return (
		<>
			{/* <Header /> */}
			<Navbar />
			<Container
				sx={{
					padding: { xs: 0 },
					mt: 8,
					display: 'flex',
					minHeight: '100vh',
					flexDirection: 'column',
				}}
				maxWidth='100%'
			>
				{children}
			</Container>
			<FooterMui />
		</>
	);
};

export default TemplateWrapper;
